import fetchAPI from "./fetchAPI"

export default async function fetchPathData(
  { path, context, isPreview, password, id },
  token
) {
  const headers = token ? { Authorization: `Bearer ${token}` } : null

  const data = await fetchAPI(
    `
      query ($path: String, $context: String, $isPreview: Boolean, $password: String, $id: Int) {
        fetchPathData(path: $path, context: $context, isPreview: $isPreview, password: $password, id: $id) {
          template
          path
          status
          redirect
          head {
            tag
            attrs
          }
          menus {
            location
            items {
              url
              target
              label
              items {
                url
                target
                label
              }
            }
          }
          copy {
            location
            strings
          }
          meta
          props {
            location
            content
          }
          content {
            name
            text
            html
            attrs
            innerBlocks {
              name
              text
              html
              attrs
              innerBlocks {
                name
                text
                html
                attrs
                innerBlocks {
                  name
                  text
                  html
                  attrs
                  innerBlocks {
                    name
                    text
                    html
                    attrs
                  }
                }
              }
            }
          }
        }
      }`,
    {
      variables: { path, context, isPreview, password, id }
    },
    headers
  )

  return data.fetchPathData
}
