import fetchAPI from "./fetchAPI"

export async function authEditor({ user, password }) {
  const { authEditor } = await fetchAPI(
    `mutation($user: String!, $password: String!) {
        authEditor(user: $user, password: $password) {
          user {
            login
            name
            email
            avatar
          }
          status
          credentials {
            type
            token
          }
        }
      }`,
    {
      variables: {
        user,
        password
      }
    }
  )

  return authEditor
}

export async function validateEditor(token) {
  const { validateEditor } = await fetchAPI(
    `mutation($token: String!) {
      validateEditor(token: $token) {
        user {
          login
          name
          email
          avatar
        }
        status
        credentials {
          type
          token
        }
        }
      }`,
    {
      variables: { token }
    },
    true
  )

  return validateEditor
}
